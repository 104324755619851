/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useContext } from 'react'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import { formatDistanceToNow } from 'date-fns'

import FlexContainer from '../../../components/FlexContainer'
import Spacing from '../../../components/Spacing'
import Card from '../../../components/Card'
import Pill from '../../../components/Pill'
import Text from '../../../components/Text'
import Title from '../../../components/Title'

import IconFollow from '../../../images/icons/ic-plus.svg'
import IconSite from '../../../images/icons/ic-site-instituicao.svg'
import IconLocation from '../../../images/icons/ic-location.svg'

import InstitutionContext from '../context'

const StyledLink = styled(Link)`
  color: #4b99c1;
  font-size: 14px;
  text-decoration: underline;
`

const actionStyle = theme => css`
  border: 2px solid ${theme.colors.celestialblue};
  border-radius: 50%;
  margin-left: 20px;
  padding: 10px;
  height: 43px;
  width: 43px;

  path {
    fill: ${theme.colors.celestialblue};
  }

  ${theme.queries.small} {
    height: 30px;
    width: 30px;
    padding: 5px;
    margin-left: unset;
  }
`
const actionStyleFirst = theme => css`
  border: 2px solid ${theme.colors.celestialblue};
  border-radius: 50%;
  margin-left: 20px;
  padding: 10px;
  height: 43px;
  width: 43px;
  margin-right: 15px;

  path {
    fill: ${theme.colors.celestialblue};
  }

  ${theme.queries.small} {
    height: 30px;
    width: 30px;
    padding: 5px;
  }
`

const Info = () => {
  const data = useContext(InstitutionContext)

  const defaultImageSrc =
    'https://via.placeholder.com/728x400.png?text=Não%20Disponível'

  const getImage = () => {
    if (data.images && data.images[0]) {
      return data.images[0]
    }
    return defaultImageSrc
  }

  return (
    <Spacing
      topMobile="10"
      className="hide-on-checkout"
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <FlexContainer
        css={theme => css`
          flex-direction: column;
          margin-bottom: 30px;

          ${theme.queries.large} {
            flex-direction: row;
          }
        `}
      >
        <Card borderDetail>
          <div
            css={theme => css`
              background-image: url(${getImage()});
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              height: 165px;
              margin: auto 0 10px;
              width: 100%;

              ${theme.queries.large} {
                height: 420px;
              }
            `}
          />
          <div
            css={theme => css`
              display: flex;
              padding: 20px;

              ${theme.queries.small} {
                flex-direction: column;
                justify-content: space-between;
              }

              ${theme.queries.large} {
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                padding: 60px 40px 40px;
              }
            `}
          >
            <FlexContainer
              css={css`
                flex-direction: column;
                margin-bottom: 0px;
              `}
            >
              <Title
                mobileSize="20"
                mobileHeight="28"
                marginBottom="11"
                size="30"
                lineHeight="30"
              >
                {data.tradingName || ''}
              </Title>

              <FlexContainer
                css={css`
                  flex-wrap: wrap;
                  span {
                    margin-bottom: 10px;
                    margin-right: 10px;
                  }
                `}
              >
                {data.categories &&
                  data.categories.map(category => (
                    <Pill key={category.name} color={category.color}>
                      {category.name}
                    </Pill>
                  ))}
              </FlexContainer>
            </FlexContainer>

            <FlexContainer
              css={theme => css`
                flex-direction: column;
                ${theme.queries.large} {
                  align-items: flex-end;
                  flex-direction: column-reverse;
                  justify-content: space-between;
                  max-height: 120px;
                  justify-content: flex-end;
                }
              `}
            >
              <FlexContainer
                css={theme => css`
                  ${theme.queries.small} {
                    justify-content: space-between;
                    align-items: center;
                  }
                `}
              >
                <Text mobileSize="13" mobileWeight="600">
                  + {data.followerCount || 0}{' '}
                  <FormattedMessage id="pages.institutions.info.followers" />
                </Text>
                <FlexContainer
                  css={theme => css`
                    ${theme.queries.small} {
                      button {
                        align-items: center;
                        display: flex;
                        flex: 1;
                        height: 43px;
                        padding-bottom: 0;
                        padding-top: 0;

                        & + button {
                          margin-left: 10px;
                        }

                        svg {
                          height: 14px;
                          margin-right: 10px;
                          width: 14px;

                          path {
                            fill: white;
                          }
                        }
                      }
                    }
                  `}
                >
                  <IconFollow css={actionStyleFirst} />
                  <IconSite css={actionStyle} />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </div>
        </Card>

        {data.campaigns ? (
          <FlexContainer
            css={theme => css`
              flex-direction: column;
              align-items: center;
              width: 100%;
              ${theme.queries.large} {
                margin-left: 40px;
              }
            `}
          >
            <Title
              as="h3"
              size="24"
              lineHeight="30"
              mobileSize="28"
              mobileAlign="center"
              mobileHeight="35"
              marginBottom="10"
            >
              <FormattedMessage id="pages.institutions.info.campaigns" /> <br />
              <FormattedMessage id="pages.institutions.info.this_institution" />
            </Title>
            <StyledLink to="/campanhas">
              <FormattedMessage id="pages.institutions.info.see_all_campaigns" />
            </StyledLink>
            <Spacing
              topMobile="25"
              css={css`
                width: 100%;
              `}
            >
              {data.campaigns.results.map(campaign => (
                <Card key={campaign.id} mobileStretch>
                  <div
                    css={theme => css`
                      padding: 0 16px 23px;
                      position: relative;
                      width: 100%;
                      flex: 1;

                      ${theme.queries.small} {
                        height: 200px;
                      }
                    `}
                  >
                    <div
                      css={css`
                        background-image: url(${campaign.imagePath});
                        background-position: center center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        height: 50px;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      `}
                    />
                    <FlexContainer
                      css={theme => css`
                        margin-top: 70px;
                        flex-direction: column;
                      `}
                    >
                      <div>
                        <Text
                          as="h4"
                          size="16"
                          weight="500"
                          lineHeight="30"
                          noMargin
                        >
                          {campaign.campaignName}
                        </Text>
                        <Text
                          size="14"
                          color="celestialblue"
                          weight="300"
                          noMargin
                        >
                          termina em{' '}
                          {formatDistanceToNow(
                            new Date(campaign.endTime),
                            'yyyy-MM-dd'
                          )}
                        </Text>
                      </div>
                      <Text
                        size="16"
                        color="gray"
                        weight="400"
                        noMargin
                        css={css`
                          margin: auto 0;
                        `}
                      >
                        {campaign.institutionName}
                      </Text>
                      <IconLocation />
                    </FlexContainer>
                  </div>
                </Card>
              ))}
            </Spacing>
          </FlexContainer>
        ) : (
          ''
        )}
      </FlexContainer>
    </Spacing>
  )
}

export default Info
