/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useContext } from 'react'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import FlexContainer from '../../../components/FlexContainer'
import Spacing from '../../../components/Spacing'
import Text from '../../../components/Text'
import Title from '../../../components/Title'

import Arrow from '../../../images/icons/ic-arrow.svg'

import InstitutionContext from '../context'

const HeaderTitle = styled(Title)`
  ${({ isHidden }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    svg {
      height: auto;
      transform: rotate(180deg) ${isHidden && `transform: rotate(0deg)`};
      transition: transform 0.4s;
      width: 15px;
    }
  `}
`

const CollpsableContent = styled.div`
  ${({ theme, isHidden }) => css`
    transition: opacity 0.2s, height 0.4s;

    ${theme.queries.large} {
      ${isHidden && `height: 0px; opacity: 0`}
    }

    ${isHidden && `height: 0px; opacity: 0`}
  `}
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    font-size: 14px;
    margin-bottom: 15px;

    ${theme.queries.large} {
      font-size: 16px;
    }
  `}
`

const Divisor = styled.hr`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    margin: 30px 0 50px;
    opacity: 0.3;
  `}
`

const Details = () => {
  const intl = useIntl()
  const data = useContext(InstitutionContext)

  const [isHidden, setIsHidden] = useState(true)

  return (
    <Spacing top="40" topMobile="20" className="hide-on-checkout">
      <HeaderTitle
        as="h3"
        mobileSize="21"
        mobileHeight="30"
        isHidden={isHidden}
        onClick={() => setIsHidden(!isHidden)}
      >
        <FormattedMessage id="pages.institutions.details.title" />
        <Arrow />
      </HeaderTitle>
      <CollpsableContent isHidden={isHidden}>
        <StyledText
          css={theme => css`
            margin-top: 15px;
            line-height: 32px;
          `}
        >
          {data.description}
        </StyledText>

        <FlexContainer
          css={theme => css`
            ${theme.queries.small} {
              flex-direction: column;
              margin-bottom: 10px;
            }

            ${theme.queries.large} {
              div + div {
                margin-left: 130px;
              }
            }
          `}
        >
          <div>
            <Title
              as="h4"
              css={theme => css`
                line-height: 25px;
                margin: 20px 0 10px;
              `}
            >
              <FormattedMessage id="pages.institutions.details.address" />
            </Title>
            <StyledText>
              {data.address ||
                intl.formatMessage({
                  id: 'pages.institutions.details.no_info',
                })}
            </StyledText>
          </div>
          <div>
            <Title
              as="h4"
              css={theme => css`
                line-height: 25px;
                margin: 20px 0 10px;
              `}
            >
              CNPJ
            </Title>
            <StyledText>
              {data.cnpj ||
                intl.formatMessage({
                  id: 'pages.institutions.details.no_info',
                })}
            </StyledText>
          </div>

          <div>
            <Title
              as="h4"
              css={theme => css`
                line-height: 25px;
                margin: 20px 0 10px;
              `}
            >
              {intl.formatMessage({
                id: 'pages.institutions.details.phone',
              })}
            </Title>
            <StyledText>
              {data.telephone ||
                intl.formatMessage({
                  id: 'pages.institutions.details.no_info',
                })}
            </StyledText>
          </div>

          <div>
            <Title
              as="h4"
              css={theme => css`
                line-height: 25px;
                margin: 20px 0 10px;
              `}
            >
              {intl.formatMessage({
                id: 'pages.institutions.details.operation',
              })}
            </Title>
            <StyledText>
              {data.operatingHour ||
                intl.formatMessage({
                  id: 'pages.institutions.details.no_info',
                })}
            </StyledText>
          </div>
        </FlexContainer>
      </CollpsableContent>
      {isHidden && <Divisor />}
    </Spacing>
  )
}

export default Details
