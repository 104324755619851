/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { Fragment, useState } from 'react'
import Layout from '../../components/Layout'
import Wrapper from '../../components/Wrapper'

import Info from './Info'
import Details from './Details'
import Donation from '../shared/Donation'

import { TYPE } from '../../utils/enum/type'

import { InstitutionProvider } from './context'

// eslint-disable-next-line react/prop-types
const TemplateInstitution = ({ pageContext }) => {
  const { institution } = pageContext

  const [isCheckout, setCheckout] = useState(() => {
    if (typeof window === 'undefined') return false

    return JSON.parse(sessionStorage.getItem('@denario_app/settings'))
      ?.isCheckout
  })

  return (
    <InstitutionProvider value={institution}>
      <Layout>
        <Wrapper>
          {!isCheckout && (
            <Fragment>
              <Info />
              <Details />
            </Fragment>
          )}
          <Donation type={TYPE.institution} />
        </Wrapper>
      </Layout>
    </InstitutionProvider>
  )
}

PropTypes.propTypes = {
  pageContext: PropTypes.shape({
    institution: PropTypes.shape({
      id: PropTypes.number.isRequired,
      evaluationCount: PropTypes.number.isRequired,
      distance: PropTypes.number.isRequired,
      city: PropTypes.string.isRequired,
      averageScore: PropTypes.number.isRequired,
      isFollowing: PropTypes.bool.isRequired,
      imagePath: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      tradingName: PropTypes.string.isRequired,
    }),
  }),
}

export default TemplateInstitution
