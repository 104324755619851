import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Pill = styled.span`
  ${({ theme, color }) => css`
    align-items: center;
    background-color: ${color};
    border: 0;
    border-radius: 29px;
    color: #fff;
    display: flex;
    font-size: 11px;
    justify-content: center;
    height: 20px;
    padding: 0 10px;

    ${theme.queries.large} {
      height: 25px;
      padding: 0 15px;
    }
  `};
`

export default Pill
